{
    font: 12px sans-serif
;
}
form#tablecontent_detailform .ant-form-item-label > label {
    white-space:break-spaces;
    height: fit-content
}

form.filter-form .ant-form-item-label > label {
    white-space: break-spaces;
    height: fit-content;
    width: 150px !important;
    max-width: 150px !important;
    padding: 0.2rem !important;
    padding-left: 0.6rem !important;
    line-height: 1.14286em;
    clear: left;
    position: relative;
}

span.separator-dot::after {
    content: ".";
    position: relative;
    float: left;
    top: -8px;
    font-size: 30px;
    padding-left:2px;
    padding-right:2px;
}


.ant-btn-dangerous {
    color: #ff4d4f !important;
}
#compact-form form{
    grid-column-gap: 0;
    column-gap: 2px;
}

#compact-form .ant-form-item{
    margin-bottom: 0 !important;
}

#compact-form .ant-table-cell{
    padding: 0 !important;
}

#inactivity_timer:before {
    content: '';
    position: fixed; /* or 'absolute' if you want it to scroll with the page */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.5); /* grey with 50% opacity */
    z-index: 999; /* make sure this is higher than the content below, but below any modals/popups */
    pointer-events: none; /* allows clicks to pass through to elements below */
}

#inactivity_timer{
    left: 35%;
    top: 20%;
}

.switchTransition{
    -webkit-animation: fadin 1.5s;
    -moz-animation:fadein 1.5s;
    -ms-animation:fadein 1.5s;
    -o-animation:fadein 1.5s;
    animation:fadein 1.5s;
}

@keyframes fadein {
    from {opacity: 0;}
    to {opacity:1;}
}
@-moz-keyframes fadein {
    from {opacity: 0;}
    to {opacity:1;}
}
@-webkit-keyframes fadein {
    from {opacity: 0;}
    to {opacity:1;}
}

@-ms-keyframes fadein {
    from {opacity: 0;}
    to {opacity:1;}
}
@-o-keyframes fadein {
    from {opacity: 0;}
    to {opacity:1;}
}

.switchTransitionUp {
    -webkit-animation: slideUp 1s;
    -moz-animation: slideUp 1s;
    -ms-animation: slideUp 1s;
    -o-animation: slideUp 1s;
    animation: slideUp 1s;
}

@keyframes slideUp {
    to {
        transform: translateY(100px); /* Start from 50 pixels below */
        opacity: 0;
    }
    from {
        transform: translateY(0); /* End at the original position */
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(0); /* Start from the original position */
        opacity: 0;
    }
    to {
        transform: translateY(-100px); /* Scroll up 50 pixels */
        opacity: 1;
    }
}

@-moz-keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 0;
    }
    to {
        transform: translateY(-100px);
        opacity: 1;
    }
}

@-webkit-keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 0;
    }
    to {
        transform: translateY(-100px);
        opacity: 1;
    }
}

@-ms-keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 0;
    }
    to {
        transform: translateY(-100px);
        opacity: 1;
    }
}

@-o-keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 0;
    }
    to {
        transform: translateY(-100px);
        opacity: 1;
    }
}
form.filter-form.ant-form-horizontal .ant-form-item{
    margin-bottom: 12px;
}
.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

span.anticon.anticon-close-circle{
    font-size:18px;
}

.ant-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

/*.ant-table-cell:hover {*/
/*    z-index: 100;*/
/*    white-space: pre-wrap !important;*/
/*    overflow: visible;*/
/*    position: relative;*/
/*    text-overflow: clip;*/
/*    max-width: none;*/
/*    !*-webkit-animation: fadin 0.8s;*!*/
/*    !*-moz-animation:fadein 0.8s;*!*/
/*    !*-ms-animation:fadein 0.8s;*!*/
/*    !*-o-animation:fadein 0.8s;*!*/
/*    !*animation:fadein 0.8s;*!*/
/*}*/



.editable .ant-pro-table-list-toolbar-setting-item{
    margin-right: 4rem;
    margin-left: -1rem;
    /*position: absolute;*/
    margin-top: 0.5rem;
    /*right: 1rem;*/
    /*z-index: 999;*/
    /*font-size: 20px;*/
    color: #1890ff;
}

.custom-blue{
    color: #1890ff;
}

.editable .ant-table-wrapper{
    padding-top: 1rem
}
.ant-pro-card .ant-pro-card-body {
    padding-inline: 10px !important;
}

.custom-collapse .ant-collapse-header,
.custom-collapse .ant-collapse-arrow {
    align-items: start !important;
}

.custom-collapse .ant-collapse-arrow {
    padding-top: 8px;
}

.ant-table-cell:has(>div.bg-orange-300), .ant-table-cell-row-hover:has(>div.bg-orange-300) {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 175, 126, var(--tw-bg-opacity)) !important;
    color: white;
    text-align: center;
}

.ant-table-cell:has(>div.bg-blue-300), .ant-table-cell-row-hover:has(>div.bg-blue-300) {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 169, 249, var(--tw-bg-opacity)) !important;
    color: white;
    text-align: center;
}

.ant-table-cell:has(>div.bg-green-300), .ant-table-cell-row-hover:has(>div.bg-green-300) {
    --tw-bg-opacity: 1;
    background-color: rgba(116, 214, 181, var(--tw-bg-opacity)) !important;
    color: white;
    text-align: center;
}
.ant-table-cell-row-hover{
    border-top:1px solid #b5b7bd !important;
    border-bottom:1px solid #b5b7bd !important;
}

.min-multi-selector {
    min-width: 12rem;
}

/*antd selector*/
.ant-select-selection-overflow {
    display: flex !important;
}

/*ant pro table*/
.ant-divider-horizontal.ant-divider-with-text {
    margin: 0px !important;
}

/*.ant-table td {*/
/*    min-width: 100px !important;*/
/*}*/


.filter-item div {
    margin: 1px 2px;
}

.ant-pro-table-list-toolbar-container, .ant-pro-table-list-toolbar {
    padding-block: 0px !important;
}

.editable .ant-space-item {
    padding: 6px;
}



#myForm-bt0 {
    column-gap: 16px;
    display: flex;
    flex-wrap: wrap;
}

#myForm-bt0 .ant-form-item {

    margin-bottom: 0px !important;
}

.editable .ant-pro-table-list-toolbar-left {
    display: none !important;
}

.editable .ant-pro-table-list-toolbar-right, .editable .ant-space-align-center.ant-pro-table-list-toolbar-right .ant-space-item:first-child {
    width: 100% !important;
}

.editable .ant-pro-table-list-toolbar-right {
    flex-wrap: nowrap;
}


/*resizer*/
.resizer {
    transition: all 0.2s ease;
    height: 100%;
}

.resizer::before {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background-color: blue;
    z-index: 10;
}

.resizer.resizing::before {
    display: block;
}


.resizer:hover, .resizing {
    border-right: 4px solid Orange;
}

button.ant-btn.ant-btn-primary.ant-input-search-button {
    border-radius: 0px 4px 4px 0px !important;
}


.ant-table-column-sorters {
    flex-direction: row-reverse;
}


button.ant-btn.ant-btn-primary.ant-input-search-button {
    border-radius: 0px 4px 4px 0px !important;
}

button.ant-btn.ant-btn-primary {
    background-image: linear-gradient(#409EFF, #407EFF);
    color:white;
}

.border-right-6.ant-picker {
    border-radius: 6px 0px 0px 6px !important;
    margin-right: 0px !important;
}

.border-left-6.ant-picker {
    margin-left: 0px !important;
    border-radius: 0px 6px 6px 0px !important;
}

.ant-row.ant-form-item-row .ant-col-8 {
    width: 150px !important;
    max-width: 150px !important;
}

.ant-form-horizontal .ant-form-item-label {
    text-align: left;
}

label.ant-form-item-required {
    position: relative;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {

    position: absolute;
    right: -10px;
}

.flex-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.react-calendar-timeline .rct-header-root {
    background-color: #1c578f !important;
}

.react-calendar-timeline .rct-dateHeader-primary {
    background-color: #1c578f !important;
}

/*.ant-tooltip-inner{*/
/*    background-color: #c6d0da !important;*/
/*}*/

.ant-modal-footer {
    display: flex;
    justify-content: space-evenly;
}

.ant-upload-drag-container {
    padding-left: 30px;
    padding-right: 30px;
}

/*.ant-table-layout-fixed table {*/
/*    table-layout: auto !important;*/
/*}*/

/*.ant-drawer{*/
/*    z-index:9999 !important*/
/*}*/
/* placeholder*/
.mask-pic {
    position: absolute;
    background: rgba(101, 101, 101, 0.4);
    color: #ffffff;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
}

.text-2xl {
    color: #396598;
}

/*font*/
.text-lg {
    font-size: 150% !important;
}

.image-holder {
    box-sizing: border-box;
    z-index: 4;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ant-layout-sider-collapsed .facility-card {
    display: none;
}

.sticky-sidebar-footer .ant-card-meta-title {
    white-space: break-spaces;

}

.treemap-tab .ant-tabs-tabpane, .treemap-tab .ant-tabs-nav-wrap {
    padding-left: 0rem;
    padding-right: 0rem;
}

/*.ant-collapse > .ant-collapse-item > .ant-collapse-header {*/
/*    padding: 0 !important;*/

/*}*/

.ant-collapse-header {
    align-items: center !important;
}

.ant-collapse-content {
    padding: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
}

/* benchmark tab*/
.tab_blue_bottom .ant-tabs-nav {
    border-bottom: 2px solid #2574a9;

}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #E7F3FD;
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
    animation: bkAnim .135s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.ant-tabs-tab-btn span {
    padding-left: 10px;
    padding-right: 10px;
}

.tab_blue_bottom .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    top: 0;
    height: 3px;
}

.tab_blue_bottom .ant-tabs-ink-bar {
    background: #2974AA !important;
}

/*@media (min-width: 750px) {*/
/*    .ant-tabs-nav-wrap, .ant-tabs-tabpane {*/
/*        padding-left: 2.5rem;*/
/*        padding-right: 2.5rem;*/
/*    }*/


/*}*/

/*@media (max-width: 750px) {*/
/*    .ant-tabs-nav-wrap, .ant-tabs-tabpane {*/
/*        padding-left: 0.2rem;*/
/*        padding-right: 0.2rem;*/
/*    }*/
/*}*/

/**/

.scroll-border .ant-table {
    background-image: linear-gradient(to bottom, rgb(244, 245, 247) 16px, transparent 16px),
    linear-gradient(to bottom, rgba(9, 30, 66, 0.13) 0px, rgba(99, 114, 130, 0) 16px),
    linear-gradient(to top, rgba(9, 30, 66, 0.13) 0px, rgba(99, 114, 130, 0) 16px);
    min-height: 900px;
}

.planning_editor_50 {
    width: 50% !important;
}

.planning_editor_30 {
    width: 40% !important;
}

.planning_progress_50 {
    width: 50%
}

.planning_progress_70 {
    width: 60%
}

.ant-collapse-content {
    padding-left: 2rem;
    padding-right: 2rem;
}

.header-0-2-3 {

    letter-spacing: 0 !important;
    height: 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

button.ant-btn.ant-btn-primary.float-adduser-button {
    position: absolute;
    right: 20px;
    margin-top: -55px;
}

.analysis .ant-page-header-content {
    margin-top: -10px;
}

@media (max-width: 850px) {
    .toolbar {
        padding-bottom: 0.5rem;
    }

    .toolbar > div {
        margin-top: 0.5rem !important;
        margin: auto !important;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: center;
    }

}

.ant-form-horizontal .ant-form-item-control{
    justify-content: center;
    padding-left: 1rem;
}

#tablecontent_detailform .ant-col.ant-form-item-control:has(.ant-form-item-control-input>.ant-form-item-control-input-content>span){
    border-bottom: 1px solid #d9d9d9;
}

/*.table-text-right td.ant-table-cell, .table-text-right th.ant-table-cell {*/
/*    text-align: right !important*/
/*}*/

/*th.ant-table-cell.ant-table-cell-fix-left {*/
/*    background-color: #e3f0fb;*/
/*}*/
.ant-form-item-control-input-content>span {

    /*font-weight: 550;*/
    /*font-size: 110%;*/
}

.ant-form-item-label>label {
    color: #6A74A5 !important;
    font-weight: 550;
    font-size: 11px;
}

.Center span.ant-input-affix-wrapper.ant-select-selection-search-input {
    border-radius: 1rem;

}

.detailForm .ant-form-item {
    margin-bottom: 1rem;
}

.Center span.ant-input-affix-wrapper.ant-select-selection-search-input input {
    text-align: center;

}

.ant-input-affix-wrapper input.ant-input {
    padding: 0px !important;
}

.ant-input-affix-wrapper, textarea, input, .ant-select-selector, .ant-picker, .ant-input-number {
    /*border-color: #E6E9F4 !important;*/
    border-radius: 4px !important;
}

/*input.ant-input{*/
/*    padding: 6px 11px !important;*/
/*}*/

.ant-input-number-input {
    height: auto !important;
}

.ant-input-number{
    width: 100% !important;
}

.ant-input-number {
    padding: 6px 2px !important;
}

.title-truncate {
    /*max-width: 12rem;*/
}

/*savings*/
.saving-stat {
    color: #6A74A5;
    font-weight: 700;
}


.benchmark-filter .ant-checkbox-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
}

.small-title {
    color: #396598;
    margin-bottom: 1rem;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 700;
}

.left-0-2-4 {
    padding: 10px !important;
}

.current-0-2-9 {
    font-size: 28px !important;
}

.small-title_piechart {
    color: rgb(57, 58, 61);
    padding-bottom: 1rem;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 700;
}

.ant-btn-primary, .ant-radio-button-checked {
    background-image: linear-gradient(#409EFF, #407EFF);
}

/*antd*/

.ant-tabs-tab-btn {
    color: rgb(57, 58, 61);
    font-size: 14px;
    font-weight: 700;
}

.ant-form-item .ant-select {
    width: 100% !important
}

.anticon svg {
    height: 1em;
    width: 1em;
}

.g2plot > div {
    z-index: 9;
}

.overlay-top {
    z-index: 9;
}

a {
    color: #1890ff !important;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}

.react-geocoder input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6px 11px;
    color: rgba(0, 0, 0, 0.85);

    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
}


.common-table .table-auto table {
    table-layout: auto !important;
}

.horizontal {
    display: flex;
    align-items: center;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

th.ant-table-cell {
    background: #f5f5f5;
    color: #999;
    height: 17px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: cell;
    vertical-align: middle;


}

.ant-table-cell, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th {
    padding: 5px !important;
}

/*#myForm .ant-form-item {*/
/*    border-bottom: 1px solid grey;*/
/*}*/

/*#myForm .ant-row.ant-form-item {*/
/*    margin-bottom: 1rem !important;*/
/*}*/

/*#myForm .ant-form-item-control-input-content input {*/
/*    margin-bottom: -15px;*/
/*}*/

/*.ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle tfoot > tr > th {*/
/*    padding: 2px !important;*/
/*}*/

.disabled-input {
    color: black !important;
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6px 11px;
    color: rgba(0, 0, 0, 0.85);

    line-height: 1.5715;
    background-image: none;
    border: 1px solid #d9d9d9;

    transition: all 0.3s;
}

body {
    background-color: #1b2431;
    color: #202020;
    /*font-family: "Montserrat", "Helvetica", "Open Sans", "Arial" !important;*/
    /*font-family: 'Poppins', sans-serif !important;*/

}


form#myForm {
    column-gap: 16px;
    display: flex;
    flex-wrap: wrap;
}

form#form_filter {
    column-gap: 0;
    grid-column-gap:0;
    display: flex;
    flex-wrap: wrap;
}

form#form_filter .filter-item div{
    margin: 0;
}

.ant-page-header-heading-extra {
    display: flex;
}

.round-button {
    z-index: 16;
    border-radius: 27px !important;
    box-shadow: 0px 4px 16px 0px rgba(179, 192, 231, 1) !important;
    width: 100%;
    justify-content: center;
    align-items: center !important;
}

.ant-page-header-footer {
    margin-top: 0 !important;
}

/*input#basic_username {*/
/*    padding-left: 1rem !important;*/
/*}*/

.ant-btn {
    display: flex !important;
}

.spin {
    position: absolute !important;
    top: 2%;
    left: 50%;
    z-index: 9999;
}

/*input#basic_password {*/
/*    border: none !important;*/
/*}*/

/*input.ant-input, .ant-input-affix-wrapper-lg, .ant-input-affix-wrapper {*/
/*    border: 0.5px solid #d9d9d95c !important;*/

/*}*/

.ant-input-affix-wrapper > input.ant-input {
    border: none !important;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 6px 0 0 6px !important;
    overflow: hidden;
}


.ant-radio-button-wrapper {
    overflow: hidden;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 6px 6px 0 !important;
    overflow: hidden;
}

.ant-btn {
    display: flex;
    align-items: center;
}

/*input.ant-input {*/
/*    padding-left: 1rem !important;*/
/*}*/

.cardWrapper {
    width: 100%;
}

.ant-btn:not(.ant-btn-circle) {
    border-radius: 4px !important;
}
.comment-list{
    background-repeat: no-repeat;
    background-image: -webkit-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -webkit-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    background-image: -moz-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -moz-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    background-position: 0 0, 0 100%;
    background-size: 100% 14px;
    background-attachment: local, local, scroll, scroll;
}
.outerCardWrapper {
    color: rgb(97, 97, 97);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: visible;
    /*border: 1px solid rgba(144, 202, 249, 0.46) !important;*/


    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: inherit !important;
    background-clip: border-box;
    border-radius: 7px;
    box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);

    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
    background-color: #fff;
    background-image: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
}

.tableWrapper {
    border-bottom: 0px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 5px;
    flex: 1 1 auto;
    min-width: 0px;
}

.center {
    display: flex;
    margin: auto;
    /*margin-top: 0.5rem;*/
}

.ant-upload-list-item-error {
    /*pointer-events: none !important;*/
}

.ant-upload-list-text-container .ant-tooltip {
    display: none;
}

input::placeholder {

}

.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 10px 24px !important;
}

.related .ant-card-head {
    padding: 0 24px;
    background: #fafafa !important;
    font-weight: bold !important;
}

.bottom-separator {
    margin-bottom: 24px !important;
}

.ant-descriptions-item-label {
    font-weight: bold !important;
}


form.ant-form.ant-form-vertical.ant-pro-form-query-filter {
    padding-bottom: 12px;
}

.ant-pro-table-list-toolbar-container, .ant-pro-table-alert-info {
    display: flex;
    justify-content: space-between;
}

.ant-pro-table-list-toolbar {
    padding: 16px 0;
}

.main-container, .main-wrapper {
    background: #fff;
    position: relative
}

.main-container {
    min-height: 500px;
    padding: 0 170px 32px 64px
}

.markdown {
    color: rgba(0, 0, 0, .85);

    line-height: 2
}

.markdown h1 {
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 20px;
    margin-top: 8px
}

.markdown h1 .subtitle {
    margin-left: 12px
}

.markdown h2 {
    font-size: 24px;
    line-height: 32px
}

.markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
    clear: both;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    margin: 1.6em 0 .6em
}

.markdown h3 {
    font-size: 18px
}

.markdown h4 {
    font-size: 16px
}

.markdown h5 {
    font-size: 14px
}

.markdown h6 {

}

.markdown hr {
    background: #f0f0f0;
    border: 0;
    clear: both;
    height: 1px;
    margin: 56px 0
}

.markdown p, .markdown pre {
    margin: 1em 0
}

.ant-row-rtl .markdown p, .ant-row-rtl .markdown pre {
    direction: rtl;
    text-align: right
}

.markdown ul > li {
    list-style-type: circle;
    margin-left: 20px;
    padding-left: 4px
}

.rtl .markdown ul > li {
    margin-left: 0;
    margin-right: 20px;
    padding-left: 0;
    padding-right: 4px
}

.markdown ul > li:empty {
    display: none
}

.markdown ol > li {
    list-style-type: decimal;
    margin-left: 20px;
    padding-left: 4px
}

.ant-row-rtl .markdown ol > li {
    margin-left: 0;
    margin-right: 20px;
    padding-left: 0;
    padding-right: 4px
}

.markdown ol > li > p, .markdown ul > li > p {
    margin: .2em 0
}

.markdown code {
    background: #f2f4f5;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    font-size: .9em;
    margin: 0 1px;
    padding: .2em .4em
}

.markdown pre {
    background: #f2f4f5;
    border-radius: 2px;
}

.markdown pre code {
    background: #f5f5f5;
    border: none;
    color: rgba(0, 0, 0, .85);
    direction: ltr;

    margin: 0;
    overflow: auto;
    padding: 0;
    text-align: left
}

.markdown b, .markdown strong {
    font-weight: 500
}

/*td.ant-table-cell {*/
/*    height: 3.5rem;*/
/*}*/

/*.ant-table-content {*/
/*    overflow: scroll;*/
/*}*/

.ant-popover-buttons {
    display: flex;
}

tr.selected_row.ant-table-row:hover > td {
    background: #0f5fa8 !important;
    cursor: pointer;
}

.selected_row_orange {
    color: red !important;
}

.selected_row {
    background-color: rgb(28, 87, 143) !important;
    color: white;
    -webkit-animation: fadin 0.8s;
    -moz-animation: fadein 0.8s;
    -ms-animation: fadein 0.8s;
    -o-animation: fadein 0.8s;
    animation: fadein 0.8s;
}

.selected_row_pink {
    background-color: pink;
    -webkit-animation: fadin 0.8s;
    -moz-animation: fadein 0.8s;
    -ms-animation: fadein 0.8s;
    -o-animation: fadein 0.8s;
    animation: fadein 0.8s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ant-table table, .ant-form, .nt-tab, .ant-input, .ant-select, .ant-picker, .ant-picker-input input, .ant-page-header,
.ant-card, .ant-radio-button-wrapper, .ant-select-item, .ant-btn, .ant-pagination, .ant-input-number, .ant-form label {

    color: #3A3F63;
}

.ant-picker {
    border: 1px solid #E6E9F4 !important;
}

.ant-tabs-tab {
    font-size: 14px !important;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
    gap: 3px !important;
}

.ant-page-header {
    padding: 0 !important
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-right: 8px;
}

.ant-input-group .ant-input {
    min-height: 2rem
}

.ant-divider-horizontal {
    margin: -0px 0 12px 0 !important;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f0f2f5;
}

.ant-modal-close {
    top: -15px;
}

label.ant-radio-button-wrapper {
    color: #1890ff;
}

.ant-steps {
    width: auto;
    min-width: 400px;
}

.ant-steps-navigation .ant-steps-item-container {
    padding-bottom: 2px;
}

.ant-steps-navigation {
    padding-top: 2px;
}

/*.ant-table {*/
/*    overflow: scroll;*/
/*}*/

thead.ant-table-thead th {
    white-space: nowrap;
}

.ant-form-item-control-input-content .ant-picker {
    width: 100%;
    /*padding: 6px 11px 6px !important;*/
}

.ant-table-thead th.ant-table-cell, th {
    text-transform: none;
}

.ant-table-thead th.ant-table-cell::first-letter, th::first-letter {
    text-transform: uppercase;
}

.ant-table-cell {
    white-space: normal !important;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 35px;
}

/*override antd style*/
.blue-btn {
    color: #1890ff;
}

.green-btn {
    color: green !important;
}

.ant-table table {
    table-layout: auto;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 4px;
    /*word-break: break-all;*/
    /*line-height: 0px;*/
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
}
.ant-table-column-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
}
.compact-table td:nth-child(2), .compact-table td:nth-child(5) {
    padding-left: 1rem;
}

.icon {
    margin-right: 0.6rem;
}

.level3-sec {
    margin-top: 0.15rem;
}

tr.Profile4Row td {
    padding: 0.2rem 0;
    font-size: 11px;
}

.benchmark {
    text-align: left;
    width: 0.5rem;
    overflow: hidden;
}

.limit {
    width: 0.5rem;
    padding-left: 0.2rem;
}

.word-wrap {
    display: block;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Sidebar .DashboardMenu a {
    color: #fff !important;
}

.Sidebar .DashboardMenu .ant-menu-item {
    height: 60px !important;
}

.corner-flower-purple {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #5e35b1 !important;
}

.corner-flower-purple:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #4527a0;
    border-radius: 50%;
}

.corner-flower-purple:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #4527a047;
    border-radius: 50%;
}

/**/

.corner-flower-orange {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #dbb47b !important;
}

.corner-flower-orange:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #DA932C;
    border-radius: 50%;
}

.corner-flower-orange:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #da932c4d;
    border-radius: 50%;
}


/**/

.corner-flower-green {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #a4d582 !important;
}

.corner-flower-green:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #66B92E;
    border-radius: 50%;
}

.corner-flower-green:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #66b92e4f;
    border-radius: 50%;
}


/**/

.corner-flower-red {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #D38D85 !important;
}

.corner-flower-red:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #D65B4A;
    border-radius: 50%;
}

.corner-flower-red:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #d65b4a3b;
    border-radius: 50%;
}

.calendar, .day-name, .event-text {

}

#myForm .ant-input-affix-wrapper, #myForm .ant-select-selector, #myForm .ant-input {
    /*border-radius: 4px;*/
    background: #fff;
    /*border-width: 1px;*/
}

#basic .InputWrapper .ant-input-affix-wrapper-lg {
    padding: 0px !important;
}

input#basic_password {
    padding: 6.5px 11px !important;
}

.ant-picker {
    min-height: 2rem;
}

.ant-table-row-level-0 .subject-editor {
    font-weight: bold
}

.ant-table-column-title {
    text-align: left;
}

#export-button a {
    color: #fff !important;
}

#export-button {
    margin-left: 1px !important;
}

/*#myForm .ant-input {*/
/*    padding: 6px 11px !important;*/
/*}*/

#myForm .ant-input-affix-wrapper {
    /* padding: 0px 11px !important; */

}

/*table*/

.global-search .ant-input-affix-wrapper {
    padding: 0 !important;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: white !important;
}

.ant-table-column-sorters span.ant-table-column-title {
    padding-left: 5px;
}

/*checkbox */
.ant-checkbox-inner, .ant-checkbox, .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:focus,
.ant-checkbox:hover, .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after{
    border-radius:50%;
}
span.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner{
    background-color: #1890ff !important;
    border-color: #1890ff !important;
}
span.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after{
    border-color: white !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-right-width: 0px !important;
}

.ant-table-row:nth-child(even) {
    background-color: #f8f8f8;
}

.ant-table-row:nth-child(odd) {
    background-color: #ffffff;
}

.ant-tag{
    border:none;
    padding: 0;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: rgb(28, 87, 143) !important;
    color: white;
}

.ant-modal-confirm-btns {
    display: flex !important;
}

.ant-comment-content-detail {
    max-width: 50vw;
}

.antd-modal-foot-center .ant-modal-confirm-btns {
    justify-content: center;
}

.echarts-for-react {
    border-radius: 10px;
    overflow: hidden;
}

.facility-select-autocomplete .ant-select-selector .ant-select-selection-search-input {
    padding: 0 20px !important;
    text-overflow: ellipsis;
}